import { FunctionComponent, useEffect, useState } from "react";
import ToggleButton from "../../../components/ToggleButton";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import SvgBrandHubBrandLogosIcon from "../../../assets/IconComponents/BrandHubBrandLogosIcon";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { FileProps } from "../../../services/BrandApi";
import { INewContent } from "../../../models/NewContent";
import LogoSelectorIcon from "../../../assets/IconComponents/LogoSelectorIcon";
import { Button } from "antd";


interface LogoSelectorProps {
  className?: string
  headerClassName?: string
  contentClassName?: string
  briefContent?: INewContent
  logoSelectionList: FileProps[]
  onSelectionChanged?: (data: FileProps[]) => void
  onListingChanged?: (data: FileProps[]) => void
  callingParent?: string;
}

const LogoSelector: FunctionComponent<LogoSelectorProps> = (
  props: LogoSelectorProps
) => {
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const newContent = useAppSelector((k) => k.content.newContent);
  const [selectionList, setSelectionList] = useState<FileProps[]>([])
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([])

  useEffect(() => {
    if (newContent?.logoFileId) {
      setCollapsed(false);
    }
  }, [newContent])

  useEffect(() => {
    if (props.briefContent) {
      setCollapsed(false)
    }
  }, [props.briefContent])

  useEffect(() => {
    if (selectionList.length === 0) {
      setSelectionList(props.logoSelectionList);
    }
  }, [props.logoSelectionList])

  useEffect(() => {
    if (selectionList.length > 0 && newContent?.logoFileId && selectionList.filter(k => k.id === newContent.logoFileId).length === 0) {
      dispatch(
        setNewContent({
          ...newContent,
          logoFileId: selectionList[0].id
        })
      );
    }
    if (selectionList.length > 0 && (props.callingParent ?? "") === "productphotoshoot") {
      dispatch(
        setNewContent({
          ...newContent,
          logoFileId: selectionList[0].id,
        })
      );
    }
  }, [selectionList])


  function selectImage() {
    if (!newContent?.logoFileId) {
      if (props.logoSelectionList.length > 0) {
        dispatch(
          setNewContent({
            ...newContent,
            logoFileId: props.logoSelectionList[0].id
          })
        );
      }
    } else {
      if (props.logoSelectionList.length > 0) {
        if (props.logoSelectionList.filter(k => k.id === newContent.logoFileId).length === 0) {
          dispatch(
            setNewContent({
              ...newContent,
              logoFileId: props.logoSelectionList[0].id
            })
          );
        }
      }
    }
  }

  useEffect(() => {
    if (props.briefContent) {
      setSelectedFileIds(props.briefContent?.logoFileId !== undefined ? [props.briefContent.logoFileId] : [])
    } else {
      setSelectedFileIds(newContent?.logoFileId !== undefined ? [newContent.logoFileId] : [])
    }
  }, [props.briefContent, newContent])

  return (
    <>
      <div
        className={`w-[394px] p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-4 inline-flex ${props.className}`}
      >
        <div
          className={`w-[366px] flex justify-between items-center ${props.headerClassName}`}
        >
          <div className="justify-start items-center gap-2.5 flex">
            <LogoSelectorIcon
              fill={collapsed ? "#8E8E8E" : "#FF5555"}
              className="flex shrink-0"
            />
            <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {(props.callingParent ?? "") === "productphotoshoot"
                ? "Add Reference Background"
                : props.briefContent
                  ? "Select Brand Logo"
                  : "Add Brand Logo"}
            </text>
          </div>

          {props.briefContent === undefined && (
            <ToggleButton
              isChecked={newContent?.logoFileId !== undefined}
              handleCheckBoxChange={function (checked: boolean): void {
                setCollapsed(!checked);
                if (checked) {
                  selectImage();
                } else {
                  dispatch(
                    setNewContent({
                      ...newContent,
                      logoFileId: undefined,
                    })
                  );
                }
              }}
              checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
              checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
              uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
              uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
            />
          )}
          {props.briefContent && (
            <div
              onClick={() => {
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.logoLibrary,
                  })
                );
              }}
              className="flex items-center self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
            ></div>
          )}
        </div>

        {!collapsed && (
          <div
            className={`self-stretch h-[142px] flex-col justify-start items-start gap-2.5 flex ${props.contentClassName}`}
          >
            {((props.briefContent &&
              props.briefContent.logoFileId &&
              selectionList.length > 0) ||
              (props.briefContent && !props.briefContent.logoFileId) ||
              !props.briefContent) && (
                <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                  <UploadFileInfinite
                    size={props.briefContent ? "brief" : "small"}
                    maxFileCount={10}
                    showRemoveBgButton={
                      (props.callingParent ?? "") !== "productphotoshoot"
                    }
                    showDeleteButton={false}
                    showRefreshButton={false}
                    automaticallySelectUploadedFile={true}
                    acceptedFileTypes={
                      (props.callingParent ?? "") === "productphotoshoot"
                        ? {
                          "image/jpeg": [".jpeg", ".jpg"],
                          "image/png": [".png"],
                          "image/webp": [".webp"],
                        }
                        : undefined
                    }
                    uploadedFiles={selectionList.slice(0, 2)}
                    title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                    description={
                      (props.callingParent ?? "") === "productphotoshoot"
                        ? "Upload .jpg, .png or .webp files. Max 25mb."
                        : "Upload .jpg or .png files. Max 25mb."
                    }
                    filesChanged={(f: any[]) => {
                      if (f && f.length > 0) {
                        const updatedFileSet = [
                          ...f.slice(0, 2).map((k) => k.uploadedId),
                        ];
                        const previousFileSet = [
                          ...selectionList.map((m) => m.id),
                        ];
                        const difference = updatedFileSet.filter(
                          (k) => !previousFileSet.includes(k)
                        );

                        setSelectionList([
                          ...f.slice(0, 2).map((k) => {
                            return { id: k.uploadedId };
                          }),
                        ]);
                        if (props.onListingChanged) {
                          props.onListingChanged([
                            ...f.slice(0, 2).map((k) => {
                              return { id: k.uploadedId };
                            }),
                          ]);
                        }

                        if (
                          difference.length > 0 &&
                          props.briefContent === undefined
                        ) {
                          dispatch(
                            setNewContent({
                              ...newContent,
                              logoFileId: difference.at(0),
                            })
                          );
                        }
                      }
                    }}
                    selectionChanged={(pickedFiles) => {
                      if (props.briefContent === undefined) {
                        if (
                          newContent?.logoFileId === undefined &&
                          pickedFiles.length === 0
                        ) {
                          return;
                        }
                        console.log(
                          `picked file ids => ${JSON.stringify(pickedFiles)}`
                        );
                        dispatch(
                          setNewContent({
                            ...newContent,
                            logoFileId:
                              pickedFiles.length > 0
                                ? pickedFiles.at(0)?.id
                                : undefined,
                          })
                        );
                      } else {
                        if (props.onSelectionChanged) {
                          props.onSelectionChanged(pickedFiles);
                        }
                      }
                    }}
                    selectionEnabled={true}
                    maxSelectionSize={1}
                    selectedFileIds={selectedFileIds}
                    maxThumbnailCount={2}
                    addNewFileToFront={true}
                  />
                </div>
              )}
            {props.briefContent === undefined &&
              (props.callingParent ?? "") !== "productphotoshoot" && (
                <div
                  onClick={() => {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        wizardView: ContentWizardView.logoLibrary,
                      })
                    );
                  }}
                  className="self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
                >
                  <a>View Library</a>
                </div>
              )}
          </div>
        )}
      </div>
    </>
  );
}

export default LogoSelector;
