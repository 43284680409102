
import { t } from "i18next";
import { Tabs } from "antd";
import AudioPlayer from "./AudioPlayer";
import { useEffect, useState } from "react";
import TextToSpeechTabContent from "./TextToSpeechTabContent";
import { FileProps } from "../../services/BrandApi";
import { setActivePage } from "../../store/appSlice";
import { useDispatch } from "react-redux";

function TextToSpeechPage() {
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);
  const [audioFile, setAudioFile] = useState<FileProps | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState<string>("1");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage("text-to-speech"));

  }, [])


  return (
    <>
      <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border items-center justify-between">
        <span className="text-H6 text-black px-5 ">Text to Speech</span>
      </div>
      <div className="w-full flex h-[calc(100vh-60px)] p-2 bg-white ">
        <div className="w-full gap-[20px] px-0 md:px-[30px] mt-2 md:mt-5 flex flex-col">
          <div className="flex flex-col gap-[10px]">
            <div className="w-full pl-40 pr-40">
              <Tabs
                defaultActiveKey="1"
                onTabClick={(c) => {
                  console.log("Tab Click", c);
                  const prevTab = currentTab;
                  if (c !== prevTab) {
                    setAudioSrc(undefined);
                  }
                  setCurrentTab(c);
                }}
                items={[
                  {
                    label: "Text",
                    key: "1",
                    children: (
                      <TextToSpeechTabContent
                        isUploadPage={false}
                        audioSrcChanged={(src) => setAudioSrc(src)}
                        audioFileChanged={(file) => setAudioFile(file)}
                      />
                    ),
                  },
                  {
                    label: "Upload",
                    key: "2",
                    children: (
                      <TextToSpeechTabContent
                        isUploadPage={true}
                        audioSrcChanged={(src) => setAudioSrc(src)}
                        audioFileChanged={(file) => {
                          console.log("Audio C: ", file);
                          setAudioFile(file);
                        }}
                      />
                    ),
                  },
                ]}
              />
              {audioSrc && <AudioPlayer audioFile={audioFile} audioSrc={audioSrc} className="mt-5" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextToSpeechPage;
