import { Close } from "../assets/IconComponents";
import { useAppSelector } from "../helpers/hooks";

export interface IDialogProps {
  title: string
  message: string
  actionButtons?: JSX.Element
  onClose?: () => void
  customTemplate?: JSX.Element
  customZIndex?: string
}

function Dialog(props: IDialogProps) {
  const showDialog = useAppSelector(state => state.app.showDialog)
  return (
    <>
      {showDialog && (
        <div
          className={`fixed lg:top-0 left-0 right-0 bottom-0 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen ${props.customZIndex ? props.customZIndex : 'z-[1001]'
            }`}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-5 rounded-[10px]">
            {!props.customTemplate && (
              <>
                <div className="h-10 items-center flex justify-between">
                  <text className="text-black text-H5 font-medium mb-3">
                    {props.title}
                  </text>
                  <button
                    onClick={() => {
                      if (props.onClose) props.onClose();
                    }}
                  >
                    <Close width={20} height={20} color="#000000" />
                  </button>
                </div>
                <hr className="bg-border -ml-5 -mr-5" />
                <text className="text-black text-BodyMedium my-5">
                  {props.message}
                </text>
                <hr className="bg-border -ml-5 -mr-5" />
                <div className="mt-3 lg:flex lg:justify-end">
                  {props.actionButtons}
                </div>
              </>
            )}
            {props.customTemplate && <>{props.customTemplate}</>}
          </div>
        </div>
      )}
    </>
  );
}

export default Dialog;
