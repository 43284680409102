import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import reportWebVitals from './reportWebVitals';
import { router } from './routes/Routes';
import { persistor, store } from './store/store';
import './styles/index.css';

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import PageLoading from './components/PageLoading';
import { ConfigProvider } from "antd";
import antDTheme from "./styles/antThemeConfig";

const firebaseConfig = {
  apiKey: "AIzaSyBh2GpGL-hspsYkAuHde7t5RUb61Awz_wU",
  authDomain: "beta2.evercopy.ai",
  projectId: "evercopywebapp-8e31b",
  storageBucket: "evercopywebapp-8e31b.appspot.com",
  messagingSenderId: "514021791867",
  appId: "1:514021791867:web:df0312bfbe7a9aac242ade",
  measurementId: "G-F3LRF1M54D"
};

//ynKuhuFUyvHfINe20hWShvh3I
//iv5GJfE1hkwt9L4GHcn4bkEhK1Z441rXV1pICeP6NSPjFDQ2bQ
//https://evercopywebapp-8e31b.firebaseapp.com/__/auth/handler

const app = initializeApp(firebaseConfig);

declare global {
  interface Window {
    Rewardful: any;
    gtag: (...args: any[]) => void;
    fbAsyncInit: (...args: any[]) => void;
    FB: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18n.on('initialized', function (options) {
  root.render(
    <Suspense fallback={<PageLoading isShowing={true} />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <ConfigProvider theme={antDTheme} >
              <RouterProvider router={router} />
            </ConfigProvider>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
})



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
