import { Add } from "../../assets/IconComponents";
import { SVGProps, useEffect, useState } from "react";
import { Tooltip } from "antd";
import { t } from "i18next";

export enum ButtonState {
  normal,
  focus,
  hover
}

export interface ILeftMenuButtonProps {
  title: string;
  onClick: () => void;
  className?: string;
  icon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined;
  deactiveColor?: string;
  state?: ButtonState;
  textColor?: string;
  collapsedMode?: boolean;
  collapsedModeIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  tooltip?: string
}
function SidebarMenuButton(props: ILeftMenuButtonProps) {
  const { icon: IconComp = Add, collapsedModeIcon: IconComp1 = Add } = props

  const [localState, setLocalState] = useState<ButtonState>(ButtonState.normal)

  useEffect(() => {
    setLocalState(props.state ?? ButtonState.normal)
  }, [props.state])

  return (
    <>
      <div
        onClick={(e) => {
          //setLocalState(ButtonState.focus)
          e.stopPropagation();
          props.onClick();
        }}
      >
        {!(props.collapsedMode ?? false) && (
          <button
            className={`
            bg-menuBg
              h-[38px] pl-[10px]
              relative
              rounded
              ${(localState === ButtonState.focus || localState === ButtonState.hover) ? "bg-menuBrandHoverBg" : ""}
             ${props.className}
            `}
            onMouseEnter={() => {
              if (localState != ButtonState.focus)
                setLocalState(ButtonState.hover);
            }}
            onMouseLeave={() => {
              if (localState != ButtonState.focus)
                setLocalState(ButtonState.normal);
            }}
            title={props.title}
          >
            <div className="flex flex-row items-center">
              <IconComp
                fill={"#000"}
                className="w-5 h-5"
              />
              <p
                className={`
                  ${props.textColor ? props.textColor : "text-black"} 
                  w-full lg:w-[140px] ml-3 whitespace-nowrap text-left text-BodyMedium text-ellipsis overflow-hidden
                  ${localState === ButtonState.focus ? 'font-medium' : ''}
                  `}
              >
                {props.title}
              </p>
              {
                localState === ButtonState.focus && (
                  <span className="border-r-black border-0 border-r-2  absolute -right-3  h-8"></span>
                )
              }
            </div>

          </button>
        )}

        {(props.collapsedMode ?? false) && (
          <Tooltip title={props.title} placement="right" color="#000000" className=" text-white text-BodyMedium">
            <div className=" relative bg-menuBg text-black text-BodyMedium cursor-pointer">
              <div className={`flex items-center  w-full h-9 p-2 rounded hover:bg-menuBrandHoverBg
                 ${props.title !== t("collapsed-view") && (localState === ButtonState.focus || localState === ButtonState.hover) ? "bg-menuBrandHoverBg" : ""}
                `}>
                <IconComp1
                  fill={"#000"}
                  className="w-5 h-5"
                  height={20}
                  width={20}
                />
                {
                  localState === ButtonState.focus && (
                    <span className="border-r-black border-0 border-r-2  absolute right-[-0.6rem]  h-8"></span>
                  )
                }
              </div>
            </div>

          </Tooltip>
        )}
      </div>
    </>
  );
}

export default SidebarMenuButton;
