import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import SvgLockIcon from "../../../assets/IconComponents/Lock";
import Warning from "../../../assets/IconComponents/Warning";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch } from "../../../helpers/hooks";
import { instanceOfApiErrorWithCode } from "../../../services/Core/BaseApi";
import { SubscribeResponse, useCardSetupMutation, useChangePlanMutation, useSubscribeMutation } from "../../../services/PaymentApi";
import { Plan } from "../../../services/PlanApi";
import { toggleLoading } from "../../../store/appSlice";

interface ICreditCardFormProperties {
    plan: Plan;
    selectedPlanType: "monthly" | "yearly";
    onSubscriptionCreated: (subscriptionResponse?: SubscribeResponse) => void;
    onPlanUpdated: (effectiveAt?: string) => void;
    skipTrial?: boolean;
    applyDiscount?: boolean;
    promotionCode: string;
    mode: "subscription" | "update";
    referral?: string
}

function CreditCardForm(props: ICreditCardFormProperties) {
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscribeResponse>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [cardSetupFinalized, setCardSetupFinalized] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const [subscribe, { data: subscriptionResult, isLoading: subscribing, error: subscribeError }] = useSubscribeMutation();
    const [setupCard, { data: setupClientSecret }] = useCardSetupMutation();

    const [changePlan, { data: planChangeResult, isLoading: isChanging, error: changePlanError, isSuccess: planChanged }] = useChangePlanMutation();

    useEffect(() => {
        dispatch(toggleLoading(isChanging));
    }, [isChanging]);

    useEffect(() => {
        if (changePlanError && instanceOfApiErrorWithCode(changePlanError)) {
            const errorData = changePlanError.data;
            setErrorMessage(errorData.message);
        }
    }, [changePlanError]);

    useEffect(() => {
        if (planChanged) {
            props.onPlanUpdated(planChangeResult?.effective_at);
        }
    }, [planChanged, planChangeResult]);

    useEffect(() => {
        if (setupClientSecret?.client_secret && elements && stripe) {
            elements.submit();
            (async () => {
                let result = false;
                result = await confirmSetup(setupClientSecret.client_secret).then((res) => res).catch(error => {
                    setErrorMessage(error);
                    setCardSetupFinalized(false);
                    return false;
                });
                if (result) {
                    setErrorMessage(undefined);
                    setCardSetupFinalized(true);
                }
            })();
        }
    }, [setupClientSecret]);

    useEffect(() => {
        if (subscribeError && instanceOfApiErrorWithCode(subscribeError)) {
            const errorData = subscribeError.data;
            setErrorMessage(errorData.message);
        }
    }, [subscribeError]);

    useEffect(() => {
        if (subscriptionResult) {
            props.onSubscriptionCreated(subscriptionResult);
        }
    }, [subscriptionResult]);

    useEffect(() => {
        dispatch(toggleLoading(subscribing));
    }, [subscribing]);

    useEffect(() => {
        if (cardSetupFinalized) {
            if (props.mode === "subscription") {
                subscribe({
                    subscribeRequest: {
                        plan_id: props.plan.id,
                        subscription_type: props.selectedPlanType === "monthly" ? "MONTHLY" : "YEARLY",
                        skip_trial: props.skipTrial ?? false,
                        apply_discount: props.applyDiscount ?? false,
                        promotion_code: props.promotionCode,
                    },
                });
            } else {
                changePlan({
                    changePlanRequest: {
                        new_plan_id: props.plan.id,
                        new_subscription_type: props.selectedPlanType === "monthly" ? "MONTHLY" : "YEARLY",
                        promotion_code: props.promotionCode,
                    },
                });
            }
        }
    }, [cardSetupFinalized]);

    const confirmSetup = async (clientSecret: string) => {
        if (!stripe || !elements) {
            dispatch(toggleLoading(false));
            return false;
        }

        dispatch(toggleLoading(true));

        return stripe
            .confirmSetup({
                elements,
                redirect: "if_required",
                clientSecret: clientSecret,
                confirmParams: { return_url: window.location.href },
            })
            .then((res) => {
                dispatch(toggleLoading(false));
                if (res.error) {
                    setErrorMessage(res.error.message);
                    return false;
                } else {
                    return true;
                }
            });
    };

    return (
        <div className="md:w-[440px] h-full self-stretch p-5 bg-stone-50 rounded-tr-xl rounded-br-xl border border-stone-200 flex-col justify-start items-center gap-10 inline-flex">
            <div className="self-stretch md:w-[400px] h-full flex-col justify-center items-start gap-3 flex">
                <div className="self-stretch justify-start items-center gap-5 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-center items-center gap-5 inline-flex">
                        <div className="self-stretch text-neutral-900 text-lg font-semibold font-['Inter Tight'] leading-[25.20px]">Payment Details</div>
                    </div>
                    <div className="justify-start items-start gap-1.5 flex">
                        <div className="w-6 h-6 pt-[4.59px] pb-[4.57px] justify-center items-center flex">
                            <div className="w-6 h-[14.83px] relative">
                            </div>
                        </div>
                        <div className="w-6 h-6 relative" />
                        <div className="w-6 h-6 pt-[7.59px] pb-[7.64px] justify-center items-center flex">
                            <div className="w-6 h-[8.77px] relative">
                            </div>
                        </div>
                    </div>
                </div>
                {errorMessage && (
                    <div className="inline-flex items-center justify-start">
                        <Warning width="20" height="20" fill="#FF5555" className="mr-2" />
                        <span className="text-red text-BodySmall">{errorMessage}</span>
                    </div>
                )}
                <div className="self-stretch h-full flex-col justify-center items-center gap-[30px] flex">
                    {/* <div className="self-stretch h-full flex-col justify-center items-center gap-2.5 flex"> */}
                    <div className="self-stretch h-full p-4 bg-white rounded-lg border border-stone-200 flex-col justify-between items-start flex overflow-y-scroll">
                        <div className="self-stretch h-full flex-col justify-start items-start gap-2.5 flex">
                            <PaymentElement></PaymentElement>
                        </div>
                    </div>
                    {/* </div> */}
                    <div className="self-stretch h-[71px] flex-col justify-center items-center gap-2.5 flex">
                        <PrimaryButton
                            className="self-stretch px-3.5 py-3 bg-red rounded-[5px] justify-center items-center gap-2.5 inline-flex"
                            title={props.mode === "update" ? "Update Plan" : (!props.skipTrial ? "Start 7-day Free Trial" : `Start Your Plan`)}
                            noFill={false}
                            onClick={function () {
                                setupCard({ cardSetupRequest: { referral: props.referral } })
                            }}
                        />
                        <div className="self-stretch justify-center items-center gap-[5px] inline-flex">
                            <SvgLockIcon className="w-4 h-4 relative"></SvgLockIcon>
                            <div className="grow shrink basis-0 text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                All payments are secure and encrypted.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default CreditCardForm;
