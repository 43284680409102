import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    removeBackground: build.mutation<
      RemoveBackgroundApiResponse,
      RemoveBackgroundApiArg
    >({
      query: (queryArg) => ({
        url: `/imageops/removebg`,
        method: "POST",
        body: queryArg.removeBackgroundRequest,
      }),
    }),
    removeBackgroundPaid: build.mutation<
      RemoveBackgroundPaidApiResponse,
      RemoveBackgroundPaidApiArg
    >({
      query: (queryArg) => ({
        url: `/imageops/paid/removebg`,
        method: "POST",
        body: queryArg.removeBackgroundRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type RemoveBackgroundApiResponse =
  /** status 200 OK */ RemoveBackgroundResponse;
export type RemoveBackgroundApiArg = {
  removeBackgroundRequest: RemoveBackgroundRequest;
};
export type RemoveBackgroundPaidApiResponse =
  /** status 200 OK */ RemoveBackgroundResponse;
export type RemoveBackgroundPaidApiArg = {
  removeBackgroundRequest: RemoveBackgroundRequest;
};
export type FileProps = {
  id?: string;
  name?: string;
  uploaded?: boolean;
  summary?: string;
  create_date?: number;
  file_name?: string;
  file_type?: string;
};
export type RemoveBackgroundResponse = {
  backgroundRemovedFile?: FileProps;
};
export type RemoveBackgroundRequest = {
  imageFileId: string;
};
export const { useRemoveBackgroundMutation, useRemoveBackgroundPaidMutation } =
  injectedRtkApi;
